.top-toolbar-body {
    width: 100vw;
    background-color: var(--primary-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    height: 72px;
    color: white;
}

.top-toolbar-body .logo-container {
    width: 50px;
    height: calc(50px );
    background-color: white;
    display: flex;
    justify-content: center;
    margin: 15px;
    border-radius: 50%;
}

.top-toolbar-body .logo-container img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: 10px;

    image-rendering: '-webkit-optimize-contrast'
}

.dropdown-backdrop {
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    position: absolute;
    z-index: 101;
}

.menu-container {
    width: auto;
    height: 100%;
    border-radius: 5px;
    background-color: var(--accent-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-btn {
    /* font-size: 21px; */
    width: 33px;
    height: 33px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.menu-container .icon {
    color: white;
    font-size: 21px;
    margin: 0;
    cursor: pointer;
}

.menu-btn:hover {
    cursor: pointer;
}

.menu-btn:active {
    transform: translateY(1px);
    box-shadow: 0px 0px 0px 9px #96a3b14f inset;
}

.dropdown-menu {
    position: relative;
    top: 60px;
    animation: popDropdown 0.15s ease-in-out ;
    /* margin-right: 1px; */
    float: right;
    background-color: whitesmoke;
    width: 180px;
    border: 1px solid var(--accent-color);
    border-radius: 5px;
    transition: all 5s ease;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
}

.dropdown-option {
    color: black;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 3px 6px;
}

.dropdown-option p {
    margin: 9px;
    -moz-user-select: none;
    /* firefox */
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE*/
    user-select: none;
    /* Standard syntax */
}

.dropdown-option:hover {
    background-color: #009c9e42;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#spinner {
  animation: spinner 0.8s ease-in-out infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@keyframes popDropdown {
	0% {
        top: 45px;
		transform: scale(0);
		transform-origin: 100% 0%;
	}

	100% {
        top: 60px;
		transform: scale(1);
		transform-origin: 100% 0%;
	}
}