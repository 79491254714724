.reset-password-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    background-color: #e7e7e7;
}

.reset-password-container .left-section,
.reset-password-container .right-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reset-password-container .left-section {
    width: 50%;
}

.reset-password-container .right-section {
    width: 50%;
}

.reset-password-container .io-logo {
    position: absolute;
    top: 15px;
    width: 200px;
}

.reset-password-container .reset-password-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 50%;
    height: 35%;
}

.reset-password-text h1 {
   color: #000;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    margin: 0;
    width: 586px;
}

.reset-password-text h3 {
    margin-top: 20px;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    color: black;
    width: 586px;
}

.reset-password-container .reset-button:hover {
    color: white;
    background-color: var(--primary-color);
    scale: 1.03;
    cursor: pointer;
}

.reset-password-container .reset-button {
    border: none;
    margin: 0px;
    border-radius: 15px;
    height: 62px;
    width: calc(100%);
    padding: 12px 41px;
    transition: all 0.2s ease-in-out;
    font-size: 16px;
    color: white;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.reset-password-form .new-password,
.reset-password-form .confirm-password {
    padding-left: 20px;
    font-size: 16px;
    width: calc(100% - 16px);
    border-radius: 15px;
    height: 62px;
    border: none;
    color: var(--primary-color);
    background-color: white;
}

.reset-password-container .show-password {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 45%;
}

.reset-password-container .visibility-icon {
    color: var(--primary-color)
}

.reset-password-container .visibility-icon:hover {
    cursor: pointer;
}


@media (max-width: 1225px) {
    .reset-password-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .reset-password-container .left-section,
    .reset-password-container .right-section {
        width: 100%;
        
    }
    .new-password{
        margin-bottom: 9px;
    }

    .reset-password-container .reset-password-form {
        height: 100%;
    }

    .reset-password-text h1,
    .reset-password-text h3 {
        text-align: center;
        width: calc(100% - 40px);
        padding: 0px 20px;
    }

    .reset-password-container .right-section {
        /* height: 20%; */
        justify-content: flex-start;
    }

    .reset-password-container .left-section {
        justify-content: flex-end;
    }
}

@media (max-width: 500px) {

    .reset-password-container .reset-password-form {
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .reset-password-container .new-password, 
    .reset-password-container .confirm-password, 
    .reset-password-container .reset-button {
        width: 80%;
    }

    .reset-password-container .right-section {
        /* height: 20%; */
        justify-content: flex-start;
    }

    .reset-password-container .left-section {
        justify-content: flex-end;
    }

}

@media (max-height: 512px) {

    .reset-password-container .reset-password-form {
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .reset-password-container .new-password, 
    .reset-password-container .confirm-password, 
    .reset-password-container .reset-button {
        width: 80%;
    }

    .reset-password-container .right-section {
        /* height: 40%; */
        justify-content: flex-start;
    }

    .reset-password-container .left-section {
        height: 50%;
        justify-content: flex-end;
    }

}

@media (max-height: 280px) {
    .reset-password-container .reset-password-form {
        height: 100%;
        gap: 5px
    }


    .reset-password-container .reset-password-text .left-section h1 {
        font-size: 30px;
    }
    
}
