.header-body {
    width: calc(96%);
    display: flex;
    flex-direction: row;
    background-color: var(--primary-color-eb);
    margin-bottom: 5px;
    border-radius: 5px;
    padding: 8px 0px;
    /* margin-right: 18px; */
}

.header-item {
    display: flex;
    justify-content: center;
    border-right: 1px solid #ffffffbd;
    color: whitesmoke;
    font-size: 16.2px;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
    height: 100%;
    align-items: center;
}

.unsorted-field {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.sorted-field {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 10px;

}

.sorting-icons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
}

.header-item:last-of-type {
    border-right: none;
}

.list-body {
    width: 96%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-width: none;
    padding-bottom: 90px;
    gap: 5px
}

.no-data-text {
    color: #a3a3a3;
    width: 100%;
    text-align: center;
}

.data-loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.list-row {
    width: calc(100%);
    height: 35px;
    background-color: white;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    /* box-shadow: 0 6px 6px -6px black; */
    cursor: pointer;
}

.list-row-text {
    display: flex;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 0;
    margin: 0;

}

.list-row-text p {
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 10px;
}

.list-row-expandable {
    width: calc(100% - 2px);
    /* height: 37px; */
    background-color: white;
    margin: 3px 0px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    cursor: pointer;
}

#expanded-row-open {
    border-bottom: 1px solid gray;
    /* transition: all 5s ease; */
}

.list-row-body {
    max-height: 0px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px inset;
}

.list-row-tabs {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0px;
    align-items: flex-start;
    background-color: lightgray;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 15px 0px 0px 0px;
    cursor: pointer;
    z-index: 1;
}

.list-row-tab-outer {
    float: left;
    /* So the psueudo elements can be abs. positioned inside */
    position: relative;

}


.list-row-tab-inner {
    /* Make them block level and only as wide as they need */
    float: left;
    padding: 5px 4px;
    text-decoration: none;

    /* Default colors */
    color: white;
    background: var(--fqc-primary-color);

    /* Only round the top corners */
    -webkit-border-top-left-radius: 15px;
    -webkit-border-top-right-radius: 15px;
    -moz-border-radius-topleft: 15px;
    -moz-border-radius-topright: 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}


.list-row-tabs .active .list-row-tab-inner {
    background-color: white;
    color: black;
}

.list-row-tabs .list-row-tab-outer::before, .list-row-tabs .list-row-tab-outer::after,
.list-row-tabs .list-row-tab-outer .list-row-tab-inner::before, .list-row-tabs .list-row-tab-outer .list-row-tab-inner::after {
    position: absolute;
    bottom: 0;
}


.list-row-tabs .list-row-tab-outer:last-child::after, .list-row-tabs .list-row-tab-outer:last-child .list-row-tab-inner:after,
.list-row-tabs .list-row-tab-outer:first-child::before, .list-row-tabs .list-row-tab-outer:first-child .list-row-tab-inner:before,
.list-row-tabs .active::after, .list-row-tabs .active::before,
.list-row-tabs .active .list-row-tab-inner::after, .list-row-tabs .active .list-row-tab-inner::before {
    content: "";
}

.list-row-tabs .active::before, .list-row-tabs .active::after {
    background: white;
    z-index: 1;
}

.list-row-tabs .list-row-tab-outer::before, .list-row-tabs .list-row-tab-outer.active::after {
    background: white;
    width: 10px;
    height: 10px;
}

.list-row-tabs .list-row-tab-outer::after {
    background: var(--fqc-primary-color);
    ;
    width: 10px;
    height: 10px;
}

.list-row-tabs .list-row-tab-outer::before {
    left: -10px;
}

.list-row-tabs .list-row-tab-outer::after {
    right: -10px;
}

.list-row-tabs .list-row-tab-outer .list-row-tab-inner::after,
.list-row-tabs .list-row-tab-outer .list-row-tab-inner::before {
    width: 20px;
    height: 20px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background: lightgray;
    z-index: 3;
}

.list-row-tabs .active .list-row-tab-inner::after,
.list-row-tabs .active .list-row-tab-inner::before {
    background: var(--fqc-primary-color);
}

.list-row-tabs .list-row-tab-outer:first-child.active .list-row-tab-inner::before,
.list-row-tabs .list-row-tab-outer:last-child.active .list-row-tab-inner::after {
    background: lightgray;
}

.list-row-tabs .list-row-tab-outer .list-row-tab-inner::before {
    left: -20px;
}

.list-row-tabs .list-row-tab-outer .list-row-tab-inner::after {
    right: -20px;
}

.list-row-tab-inner p {
    padding: 4px 40px;
    margin: 0;
    border-radius: 10px;
}

.inactive .list-row-tab-inner p {
    position: relative;
    background-color: var(--fqc-primary-color);
    color: white;
    z-index: 5;
}

.active .list-row-tab-inner:hover p {
    background-color: white;
}

.inactive .list-row-tab-inner:hover p {
    background-color: var(--fqc-primary-color-eb);
    transition: background-color 0.3s ease;
}

.list-body-sub-section {
    width: calc(100% - 30px);
    padding: 0px 15px;
    margin-bottom: 3px;
    flex-direction: column;
    justify-content: space-around;
}

.list-body- .list-body-sub-section {
    opacity: 0;
    display: none;
}

.list-body-sub-section-sub-check {
    width: calc(100%);
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid gray;
    box-sizing: border-box;
}

.list-body-sub-section-sub-check:last-of-type {
    border-bottom: none;
}

.list-body-sub-section-sub-check p {
    margin: 0;
    /* vertical-align: text-bottom; */
    padding: 0px 10px;
    line-height: 40px;
    height: 100%;
    border-right: 1px solid gray;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100px;
}

.list-body-sub-section-sub-check .final-column-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0px 10px;
    border-left: none;
    height: 100%;
}

.list-body-sub-section-sub-check .final-column-container #media-open .media-icon-backdrop {
    background-color: red;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
}

.media-icon-button {
    background-color: white;
    color: var(--primary-color);
    border-radius: 100px;
    font-size: 15px;
    height: 30px;
    padding: 5px;
    width: 30px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.media-icon-button:hover {
    background-color: var(--primary-color);
    color: white;
    border-radius: 100px;
}

.media-icon-container .close-icon {
    color: black;
}

.media-icon-container .close-icon:hover {
    color: var(--primary-color);
    ;
}

.final-column-container .media-icon-container {
    position: relative;
    min-width: 42px;
    min-height: 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    overflow: hidden;
    z-index: 120;
    padding: 5px;
    border-radius: 25px;
}

.media-icon-button-container {
    min-width: 42px;
    min-height: 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    overflow: hidden;
    z-index: 100;
    padding: 5px;

    border-radius: 25px;
}

.list-body-sub-section-sub-check .final-column-container #media-close {
    animation: close-media-container 0.3s forwards;
}

.list-body-sub-section-sub-check .final-column-container #media-open {
    animation: open-media-container 0.3s forwards;
}

.list-body-sub-section-sub-check .final-column-container #media-open .media-button {
    animation: transparent-to-white 0.3s forwards;
}

.list-body-sub-section-sub-check .final-column-container #media-close .media-button {
    animation: white-to-transparent 0.3s forwards;
}

@keyframes white-to-transparent {
    0% {
        background-color: white;
    }

    100% {
        background-color: transparent;
    }

}

@keyframes transparent-to-white {
    0% {
        background-color: transparent;
    }

    100% {
        background-color: white;
    }

}

@keyframes close-media-container {
    0% {
        gap: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        background-color: white;
    }

    100% {
        gap: 0px;
        box-shadow: none;
    }
}

@keyframes open-media-container {
    0% {
        gap: 0px;
        box-shadow: none;
    }


    100% {
        gap: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        background-color: white;
    }

}

.list-body-sub-section-sub-check .final-column-container .check-media-button {
    color: var(--primary-color);
    font-size: 20px;
    cursor: pointer;

}

.list-body-sub-section-sub-check .final-column-container .check-media-button:hover {
    color: var(--primary-color-eb);
}

.list-body-sub-section .check-table {
    width: calc(100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid gray;
}

.list-body-sub-section .check-table-fields {
    width: calc(100%);
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid gray;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.list-body-sub-section .check-table-fields p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0px 10px;
    border-right: 1px solid gray;
    height: 100%
}

.list-body-sub-section .check-table-fields p:last-of-type {
    border-right: none;
}

.list-body-sub-section h2 {
    padding-left: 18px;
}

.list-body-sub-section-sub-check .check-title {
    width: calc(100% - 30px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    background-color: #f0f0f0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 0px 15px;

}

.list-body-sub-section-sub-check .check-content {
    width: calc(100% - 30px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f0f0f0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 0px 15px;
}

.list-body-sub-section-sub-check .check-content .right,
.list-body-sub-section-sub-check .check-content .left {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.list-body-sub-section-sub-check .check-content .check-text {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.list-row-toggle-button {
    width: 100%;
    background-color: silver;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.list-row:hover {
    background-color: whitesmoke;
    transition: all 0.3s;
}

.row-item {
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid gray;
}

.permission-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 80%;
}

.permission-box p {
    margin: 0px;
}

.color-indicator {
    width: 12px;
    height: 12px;
    opacity: 0.3;
    border-radius: 100px;
}


/* @media only screen and (max-width: 600px) { */
.row-item p {
    max-height: 21px;
    padding: 7px 6px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.row-item:last-of-type {
    border-right: none;
}


.list-row-details-container {
    display: flex;
    /* width: 50%; */
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding: 2px;
}

.list-row-details-container button {
    height: 24px;
    width: 24px;
    margin: 1px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(245, 245, 245, 0.759);
    border: 0px solid white;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0.5px 0.5px, rgba(0, 0, 0, 0.3) 0px 0px 1px 0px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.list-row-details-container button:active {
    transform: translateY(1px);
}

.action-box {
    width: 100%;
    display: flex;
    height: 100%;
}

.action-box button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    background-color: transparent;
    border: 0;
}

.action-box button svg {
    font-size: 21px;
    color: var(--accent-color);
    cursor: pointer;
}

.action-box button svg:hover {
    font-size: 24px;
}

#dismiss-btn {
    border-radius: 9px;
    border: 1px solid #a3a3a3;
    background-color: transparent;
    color: #808080;
    cursor: pointer;
    margin-right: 1px
}

.list-row-loader {
    margin: 20px
}


#dismiss-btn:hover {
    background-color: #00000014;
}

#dismiss-btn:active {
    box-shadow: inset 0 2px 2px -2px black;
}

.list-body-open {
    animation: slide-open-list 0.3s forwards;
    overflow: scroll;
    scrollbar-width: none;
}

.list-body-close {
    animation: slide-close-list 0.3s forwards;
}

.list-body-open .list-body-sub-section {
    animation: list-show-open 0.4s forwards;
}

.list-body-close .list-body-sub-section {
    animation: list-show-close 0.4s forwards;
}

.final-signoffs {
    width: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 0px 15px 30px;

}

.signoff-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signoff-container .signoffs {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: space-between;
    border-radius: 5px;
}

.signoff-container .signoff-box {
    height: 40px;
    width: var(--signoff-width);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
    border-left: none;
}

.signoff-container .signoffs .signoff {
    height: 40px;
    width: 100%;
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.signoff-container .signoffs .signoff-box:last-child {
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.signoff-container .signoffs .signoff-box:first-child {
    border-left: 1px solid gray;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

/* ANIMATIONS */
@keyframes slide-open-list {
    0% {
        max-height: 0px;
        height: 0px;
    }

    100% {
        max-height: 500px;
        height: 500px;
    }
}

@keyframes slide-close-list {
    0% {
        max-height: 500px;
    }

    100% {
        max-height: 0px;
    }
}

@keyframes list-show-open {
    0% {
        display: none;
        opacity: 0;
    }

    75% {
        display: flex;
        opacity: 0;
        justify-content: center;
        align-items: center;
    }

    100% {
        display: flex;
        opacity: 1;
        justify-content: center;
        align-items: center;
    }
}

@keyframes list-show-close {
    0% {
        display: flex;
        opacity: 0;
    }

    100% {
        display: none;
        opacity: 0;
    }
}