.login-backdrop {
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-image: url("/src/media/bg.png");
    overflow: hidden;
}

.login-container-box {
    width: 390px;
    height: 330px;
    padding: 24px;
    background-color: #e7e7e7;
    margin-top: 27vh;
    border-radius: 9px;
    box-shadow: 0px 0px 9px 9px rgba(0, 0, 0, 0.093);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9;
}

.login-logo-container {
    background-size: cover;
    background-image: url("/src/media/ColcabLogoNoText.png");
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#login-logo-spinning {
    animation: spin 1s ease-in-out infinite;

}

/* #login-logo {
    width: 120px;
    background-size: cover;
    image-rendering: -webkit-optimize-contrast;
    border-radius: 20px
} */

.invalid-details-text {
    margin: 9px 0;
    font-size: 12px;
    color: red;
    font-weight: 400;
    width: 300px;
    background-color: #f8d7d7;
    border: 1px solid red;
    padding: 6px;
    text-align: center;
}

.login-form {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 2%;
}

.form-group label {
    color: rgb(0, 0, 0);
    font-size: 15px;
    margin: 2px;
    text-align: center;
    font-weight: 600;
}

.form-group input {
    border-radius: 3px;
    border: 1px solid #2c426e;
    font-size: 12px;
    width: 300px;
    padding: 6px;
    background-color: rgba(255, 255, 255, 0.471);
    /* box-shadow: 0 3px 3px -3px black; */

}

#login-button {
    margin: 39px 0 15px;
    background-color: #009b9e;
    color: white;
    border-radius: 90px;
    height: 30px;
    border: 0px;
    font-weight: bold;
    cursor: pointer;
    width: 300px;
    /* box-shadow: 0 6px 6px -6px black; */
}
#login-button:active {
   
    box-shadow: inset 0 6px 3px -3px #444444;
}

.random-container {
    position: relative;
    box-shadow: inset 0px 0px 9px 9px rgba(0, 0, 0, 0.093);
    border-radius: 15px;
}

/* LoadingSpinner */
@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.login-header-1 {
    position: fixed;
    margin: 0;
    padding: 30px;
    font-family: 'Blinker', sans-serif;
    font-size: 42px;
    font-weight: 500;
    color: #009b9e;
    width: 100vw;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.213);
    letter-spacing: 3px;
    text-transform: uppercase;
}