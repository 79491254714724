.dashboard-body {
    animation-duration: .6s;
    animation-name: body-fade-in;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
}

@keyframes body-fade-in {
    0% {
        opacity: 0.0;
    }

    100% {
        opacity: 1.0;
    }
}