.overview-container-body {
    width: calc(100% - 240px);
    height: calc(100vh);
    background-color: #e0e0e0;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.5s;
}

.overview-container-body[trigger='collapse'] {
    height: calc(100vh - 210px);
}