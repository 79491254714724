.grid-scroll-body {
    width: calc(96% + 20px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: scroll;
    /* gap: 0.5rem; */
    padding: 0px 10px 10px 10px;
    scrollbar-width: none;
}

.grid-box-container {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(12.5% - 18px);
    margin: 9px;
    height: auto;
    min-height: 100px;
    background-color: white;
    color: var(--accent-color);
    /* border: 1px solid var(--accent-color); */
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0px;
    align-items: center;
    transition: all 0.2s ease-in-out;
}

.grid-box-container h4 {
    width: 163px;
    max-width: 163px;
    text-overflow: ellipsis;
    overflow: hidden;
    /* padding: 0px 10px;  */
    height: 100%;
}

.grid-box-details-container-parent {
    display: flex;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 30px;
}

.grid-box-details-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding: 5px;
}

.grid-box-details-container button {
    height: 24px;
    width: 24px;
    margin: 1px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(255, 255, 255, 0.816);
    border: 0px solid white;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0.5px 0.5px, rgba(0, 0, 0, 0.3) 0px 0px 1px 0px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.grid-box-details-container button:active {
    transform: translateY(1px);
}

.grid-box-with-header-container {
    width: calc(20% - 18px);
    height: 120px;
    background-color: transparent;
    color: white;
    margin: 9px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    /* border: 1.5px solid var(--primary-color); */
    transition: all 0.3s ease-in-out;
}

.grid-box-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 4px;
    background-color: var(--accent-color);

}

.grid-box-with-header-container h4 {
    width: 100%;
    background-color: var(--accent-color);
    margin: 0;
    padding: 3px 0;
    display: flex;
    align-items: center;
    justify-content: start;
}

.grid-box-with-header-container .box-body {
    flex: 1;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 12px 0;
}

.grid-info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 6px;
}


.grid-info-container p {
    color: black;
    margin: 0 9px;
    font-size: 15px;
    font-weight: 500;
}


@media (max-width: 1711px) {
    .grid-box-container {
        width: calc(14.285714% - 18px);
    }
}

@media (max-width: 1526px) {
    .grid-box-container {
        width: calc(16.6666666% - 18px);
    }
    .grid-box-with-header-container {
        width: calc(25% - 18px);
    }
}

@media (max-width: 1341px) {
    .grid-box-container {
        width: calc(20% - 18px);
    }
    .grid-box-with-header-container {
        width: calc(33.333333333333333333% - 18px);
    }
}

@media (max-width: 1156px) {
    .grid-box-container {
        width: calc(25% - 18px);
    }
    .grid-box-with-header-container {
        width: calc(50% - 18px);
    }
}