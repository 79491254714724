.pullup-details-container {
    position: fixed;
    width: calc(100% - 240px - 2px);
    top: 100vh;
    left: 240px;
    height: calc(100vh);
    background-color: #ebebed;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    border: 1px solid white;
    overflow: hidden;
}

.pullup-toggle-button {
    width: 100%;
    height: 29px;
    background-color: var(--secondary-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: whitesmoke;
    font-size: 30px;
    border-bottom: 1px solid white;
    cursor: pointer;
}

.pullup-details-body {
    width: 100%;
    height: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    background-color: #cad6ed;
}


.start-body-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 210px;
}

.end-body-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100% - 210px);
    overflow: hidden;
    align-items: center;
}

.checksheets-scroll-container {
    width: calc(100% - 36px);
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    overflow-y: scroll;
    margin: 0 18px;
    border-radius: 6px;
    border: 1px solid white;
}

.details-box-container {
    /* width: 21%; */
    height: 150px;
    background-color: #15315449;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.details-box-header {
    width: 100%;
    height: 30px;
    background-color: var(--accent-color);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.details-box-header h4 {
    margin: 0;
    text-align: center;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 6px;

}

.loader-container {
    width: calc(100% - 4px);
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.details-box-body {
    width: calc(100% - 4px);
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: 2px solid var(--accent-color);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    color: white
}

.detail-container {
    display: flex;
    flex-direction: row;
    padding-left: 9px;
}

.detail-container h4 {
    margin: 0px;
    margin-left: 9px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

}

.progress-bar-container {
    width: 84%;
    border: 1px solid black;
    height: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2px;
    margin: 30px;
    background-color: aliceblue;
}

.progress-bar {
    background-color: var(--accent-color);
    height: 100%;
    margin-left: -36px;
}

.progress-bar-container h4 {
    position: relative;
    margin: 0;
    width: 36px;
    left: calc(50% - 18px);
    color: black;
}

#hide-show {
    animation: show-up 0.5s forwards;
    -webkit-animation: show-up 0.5s forwards;
}

#show-hide {
    animation: hide-down 0.5s forwards;
    -webkit-animation: hide-down 0.5s forwards;
}

@keyframes show-up {
    0% {
        top: 100vh;
    }

    100% {
        top: calc(100vh - 30px - 210px);
    }
}

@keyframes hide-down {
    0% {
        top: calc(100vh - 30px - 210px);
    }

    100% {
        top: 100vh;
    }
}

#slide-up {
    animation: slide-up 0.5s forwards;
    -webkit-animation: slide-up 0.5s forwards;
}

#slide-down {
    animation: slide-down 0.5s forwards;
    -webkit-animation: slide-down 0.5s forwards;
}


@keyframes slide-up {
    0% {
        top: calc(100vh - 30px - 210px);
    }

    100% {
        top: 0px;
    }
}

@keyframes slide-down {
    0% {
        top: 0px;
    }

    100% {
        top: calc(100vh - 30px - 210px);
    }
}

@-webkit-keyframes slide-up {
    0% {
        top: calc(100vh - 30px - 210px);
    }

    100% {
        top: 72px;
    }
}

@-webkit-keyframes slide-down {
    0% {
        top: 72px;
    }

    100% {
        top: calc(100vh - 30px - 210px);

    }
}



#body-slide-up {
    animation: fade-in 0.5s forwards;
    -webkit-animation: fade-in 0.5s forwards;
}

#body-slide-down {
    animation: fade-out 0.5s forwards;
    -webkit-animation: fade-out 0.5s forwards;
}

#start-slide-up {
    animation: start-fade-in 0.5s forwards;
    -webkit-animation: start-fade-in 0.5s forwards;
}

#start-slide-down {
    animation: start-fade-out 0.5s forwards;
    -webkit-animation: start-fade-out 0.5s forwards;
}

#end-slide-up {
    animation: end-fade-in 0.5s forwards;
    -webkit-animation: end-fade-in 0.5s forwards;
}

#end-slide-down {
    animation: end-fade-out 0.5s forwards;
    -webkit-animation: end-fade-out 0.5s forwards;
}


/* top: calc(100vh - 30px - 210px); */
/* top: calc(72px); */
@keyframes fade-in {
    0% {
        opacity: 1;
        flex-direction: column;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        flex-direction: row-reverse;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
        flex-direction: row-reverse;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        flex-direction: column;
    }
}

@keyframes start-fade-in {
    0% {
        flex-direction: row;
        height: 210px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    100% {
        flex-direction: column;
        height: calc(100% - 67px - 90px);
        width: 300px;
        padding: 0 21px;
        margin: 67px 0 90px;
        justify-content: space-between;

    }
}

@keyframes start-fade-out {
    0% {
        flex-direction: column;
        height: calc(100% - 67px - 90px);
        width: 300px;
        padding: 0 21px;
        margin: 67px 0 90px;
        justify-content: space-between;
    }

    100% {
        flex-direction: row;
        height: 210px;
        width: 100%;
        padding: 0;
        margin: 0;

    }
}

@keyframes end-fade-in {
    0% {
        height: 0;
    }

    100% {
        height: 100%;
    }
}

@keyframes end-fade-out {
    0% {
        height: 100%;
    }

    100% {
        height: 0;
    }
}