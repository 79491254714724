.overview-toolbar-body {
    width: 96%;
    height: 60px;
    min-height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.search-and-filter-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.search-and-filter-container .sort-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.search-and-filter-container .sort-container .sort-icon-container {
    width: 35px;
    height: 35px;
    border-radius: 6px;
    /* border: 1px solid whitesmoke; */
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;

}

.search-and-filter-container .sort-container .sort-select {
    outline: none;
    width: calc(150px);
    position: relative;
    height: 35px;
    padding: 0px 10px;
    border-radius: 6px;
    /* border: 1px solid whitesmoke; */
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;


}

.search-and-filter-container .sort-container .sort-icon-container .sort-icon {
    width: 20px;
    height: 20px;
}


.searchbar-container {
    height: 30px;
    display: flex;
    align-items: center;
    border: none;
    /* box-shadow: 0 3px 3px -3px black; */
}

.searchbar {
    margin: 0;
    padding: 5px 15px;
    border-radius: 5px;
    background-color: white;
    color: black;
    outline: none;
    border: none;
    /* border: 1px solid var(--accent-color); */
    font-weight: 500;
    width: 210px;
    height: 25px;
}

.searchbar:focus {
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    transition: box-shadow 0.3s;

}

.search-button {
    color: var(--accent-color);
    border: 2px solid var(--accent-color);
    border-left: 0px;
    border-radius: 0 3px 3px 0;
    height: 23px;
    cursor: pointer;
}

.filter-tool-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    border-radius: 3px;
    overflow: hidden;
    border: 2px solid var(--accent-color);
    height: 31px;

}

.filter-tool-box button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    height: 100%;
    cursor: pointer;
}


.tool-box {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

}

.tool-box button {
    height: 35px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    border: 1px solid gray
}

.tool-box .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: #7d8999;
    border: 1px solid #284160;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    top: 5px;
    right: 115%;

}

.tool-box button:hover .tooltip-text {
    visibility: visible;
    transition: all ease-in-out 0.5s;
}

.tool-box button:active {
    transform: translateY(1px);
}

.notification-number {
    position: fixed;
    transform: translate(70%, -90%);
    /* top: -36px;
    left: 20px; */
    border-radius: 25px;
    color: white;
    padding: 2px 6px;
    z-index: 100;
    font-size: 12px;
}

.create-new-button {
    width: 120px;
    background-color: var(--accent-color);
    color: whitesmoke;
    font-weight: 600;
}

.dev-icon {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    /* border: 1px solid whitesmoke; */
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

}