.side-menu-body {
    width: 240px;
    height: 100%;
    display: flex;
    background-color: var(--secondary-color);
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    
    
}

.options-container {
    width: 100%;
    height: calc(100% - 210px);
    
}

.colcab-logo-container {
    width: 100%;
    height: auto;
    /* background-color: aliceblue; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.colcab-logo-container img {
    background-color: white;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin: 10px 0px 10px 10px;
    padding: 5px;
    image-rendering: '-webkit-optimize-contrast';
}

.colcab-logo-container h3 {
    color : white;
    margin: 0px;
    padding: 0px;
}

.colcab-logo-container .logo-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

}

.menu-option-button {
    /* background-color: var(--accent-color); */
    width: calc(100% - 30px);
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    -webkit-user-select: none;
    padding: 0 10px;
    gap: 10px;
    margin: 12.5px 5px; 
    border-radius: 5px;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
    transition: all 0.3s ease;
    
    
}

/* .menu-option-button:first-child {
    border-top: 2px solid white;

} */

.menu-option-button:hover {
    cursor: pointer;
    box-shadow: rgba(6, 24, 44, 0.1) 0px 0px 0px 2px, rgba(6, 24, 44, 0.25) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    transition: all 0.4s ease ;
    
}

.menu-option-button h4 {
    margin: 0;
    color: black;
    flex: 4;
}

#logo-colcab {
    height: 150px;
    width: 150px;
    padding: 5px;
    border-radius: 100px;
    object-fit: contain;
    margin: 30px;
}

.col-logo {
    width: calc(100% - 30px);
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

}

#logo-fl {
    width: 100px;
    object-fit: contain;
    
}

@media only screen and (max-height: 633px) {
    .logo-container {
        padding-top: 120px;
    }

    #logo-colcab {
        display: none;
    }
}