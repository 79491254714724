.dev-notes-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.dev-notes-backdrop .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1200;
    background-color: white;
    border: 2px solid var(--primary-color-eb);
    border-radius: 15px;
    padding: 20px;
    width: 500px;
    height: 350px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
}

.dev-notes-backdrop .modal .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.dev-notes-backdrop .modal .content .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -20px;
}

.dev-notes-backdrop .modal .content .header h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0;
    color: var(--primary-color);
}

.dev-notes-backdrop .modal .content .header .close {
    cursor: pointer;
    color: var(--primary-color);
    height: 30px;
    
}

.dev-notes-backdrop .modal .content .body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.dev-notes-backdrop .modal .content .body input,
.dev-notes-backdrop .modal .content .body textarea {
    font-size: 0.9rem;
    color: var(--primary-color);
    border-radius: 5px;
    outline: none;
    border: 1px solid var(--primary-color);
    padding: 5px;
}

.dev-notes-backdrop .modal .content .body input {
    width: calc(100% - 10px);
    height: 30px;
    margin-bottom: 10px;
}

.dev-notes-backdrop .modal .content .body textarea {
    height: 100%;
    resize: none;
    width: calc(100% - 10px);
}

.dev-notes-backdrop .modal .content .footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.dev-notes-backdrop .modal .content .footer button {
    padding: 5px 10px;
    width: 100px;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 600;
    color: white;
    background-color: var(--primary-color);
}