:root {
  scrollbar-color: #9badd1 transparent;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --sqc-primary-color: #ffffff43;
  --sqc-primary-color-eb: #153154eb;
  --sqc-secondary-color: #153154;
  --sqc-accent-color: #153154;
  --sqc-selected-row-color: #15315454;

  --fqc-primary-color: #236d71;
  --fqc-primary-color-eb:#194e51eb;
  --fqc-secondary-color: #194e51;
  --fqc-accent-color: #153154;
  --fqc-selected-row-color: #236d7193;

  --primary-color: #236d71;
  --primary-color-eb:#194e51eb;
  --secondary-color: #194e51;
  --accent-color: #153154;
  --selected-row-color: #9cadaf;

  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 18px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* body::-webkit-scrollbar {
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
} */