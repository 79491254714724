.checksheet-details-container {
    width: 90%;
    background-color: transparent;
    margin-top: 12px;
}

.checksheet-details-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 24px;
    border-radius: 6px;
    border: 1px solid black;
    background-color: #e7e6ff;
}

.checksheet-details-header h5 {
    text-align: center;
    margin: 0;
    border-right: 1px solid black;
    padding: 3px;
    color: rgb(0, 0, 0);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.checksheet-dropdown-button {
    border-right: 0;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000033;
    cursor: pointer;
}

.checksheet-dropdown-button:active {
    box-shadow: 0px 0px 0px 9px #96a3b14f inset;
}

.checksheet-details-body {
    padding: 9px;
    border-top: 0;
    background-color: #9badd164;
    margin-left: 3px;
    margin-right: 2px;
    border-radius: 0 0 6px 6px;
}

.checksheet-details-check-container:last-child {
    margin-bottom: 0px;
}

.checksheet-details-check-container {
    width: calc(100%);
    height: 21px;
    margin: 0px 0px 9px;
    background-color: rgba(255, 255, 255, 0.613);
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    border: 1px solid black;
}

.checksheet-details-check-container h6 {
    font-size: 13px;
    text-align: center;
    line-height: 12px;
}

.check-title {
    margin: 0;
    width: 150px;
    padding: 6px;
    text-align: start;
}

.check-state-container {
    background-color: green;
    flex: 6;
    padding: 3px;
    color: black;
    text-align: center;
    border-left: 1px solid black;
    border-right: 1px solid black;
    font-weight: 500;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 100px;
}

.misc-container {
    width: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}


.action-btn svg:hover {
    font-size: 22px;
}

