.unauth-body {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.unauth-button {
    background-color: #153154;
    color: white;
    padding: 12px 30px;
    border-radius: 12px;
    margin: 24px;
    border: 0px;
    cursor: pointer;
    box-shadow: 0 6px 6px -6px black;
}

.unauth-button:active {
    box-shadow: inset 0 6px 3px -3px #444444;
}