/* custom alert modal */

.alert-modal-container {
    position: fixed;
    background-color: var(--primary-color);
    width: 342px;
    height: 66px;
    /* border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px; */
    top: -72px;
    left: calc(50vw - 171px);
    z-index: 100;
    padding: 1px;
    border-radius: 15px;

}

.alert-modal-body {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    /* border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    color: white
}

.alert-message-text{
    color: white;
    font-size: 13px;
    width: 80%;
    display: -webkit-box; 
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 2; 
    text-overflow: ellipsis;
    overflow: hidden;
    /* white-space: nowrap; */
}

#alert-in {
    animation-duration: 1s;
    animation-name: slidein;
    animation-iteration-count: 1;
    animation-direction: alternate;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
    transition: all 0.5s ease-in-out;
}

#alert-out {
    animation-duration: 1s;
    animation-name: slideout;
    animation-iteration-count: 1;
    animation-direction: alternate;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    box-shadow: none;
    transition: all 0.5s ease-in-out;
}

@keyframes slidein {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(90px);
    }
}

@keyframes slideout {
    0% {
        transform: translateY(90px);
    }

    100% {
        transform: translateY(0px);
    }
}