.backdrop {
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
}

.modal-body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    background-color: whitesmoke;
    border: 1.5px solid white;
    border-radius: 10px;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 99;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.modal-header {
    width: 100%;
    background-color: var(--accent-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: auto;
}

.modal-title {
    margin: 9px;
    font-weight: 600;
    color: white;
    text-align: center;
}

.input-form {
    padding: 9px;
}

.form-group-vertical {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 15px;
}

.horizontal-align {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.form-group-horizontal {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 15px;
    border: 1px solid var(--accent-color);
    padding: 9px;
    border-radius: 3px;
    background-color: white;
}

.form-group-vertical .col-form-label {
    display: block;
    /* width: 100%; */
    font-size: 12px;
    font-weight: 400;
}

.modal-input {
    border: 1px solid var(--accent-color);
    border-radius: 3px;
    padding: 0px;
    padding-left: 10px;
    height: 30px;


}

.add-icon {
    display: inline-block;
    position: relative;
}

.add-icon .icon {
    margin-top: 5px;
}

.add-icon .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: #7d8999;
    border: 1px solid #284160;

    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    top: 2px;
    right: 105%;
}

.add-icon:hover .tooltip-text {
    visibility: visible;
}

.submit-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.submit-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 36px;
    justify-content: center;
    align-items: center;
    border: 0;
    background-color: var(--accent-color);
    color: white;
    font-weight: 600;
    font-size: 15px;
    text-transform: uppercase;
}

.back-btn {
    border: 2px solid var(--accent-color);
    background-color: transparent;
    color: var(--accent-color);
    border-bottom-left-radius: 8.5px;
}

.submit-btn:hover {
    cursor: pointer;
}

.checks-scroll-container {
    overflow-y: scroll;
    scrollbar-width: none;
    max-height: 360px;
}

.checks-header {
    display: flex;
    flex-direction: row;
    border: none;
    background-color: #153154ca;
    border-radius: 5px;
    margin-top: 9px;
    margin-bottom: 1px;
    width: calc(100% - 20px);
}

.checks-header h4 {
    margin: 0;
    padding: 5px 10px;
    color: white;
    font-weight: 600;
}


.check-item {
    display: flex;
    flex-direction: row;
    border-top: 0;
    max-height: 40px;
    /* align-items: center; */

}

.checkbox-container {
    width: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    margin: 1px;
}

.check-item-sub-modal {
    display: flex;
    flex-direction: row;
    border-top: 0;
}

.check-item-sub-modal input {
    padding: 5px;
    width: calc(100% - 39px);
    font-weight: 500;
    border-radius: 5px;
    border: 1px solid black;
    outline: none;
    /* border-top: 0px;
    border-right: 0px; */
    /* margin: 1px; */
    margin-top: 2px;
    margin-right: 2px
}

.check-item-sub-modal .form-group-vertical .modal-input {
    padding: 3px;
    font-weight: 500;
    border-radius: 0;
    border: 1px solid black;
    resize: none;
}

.check-item input {
    padding: 3px;
    font-weight: 500;
    border-radius: 0;
    border: 1px solid black;
    /* border-top: 0px;
    border-right: 0px; */
    margin: 1px;
    /* height: 30px; */
    text-overflow: ellipsis;

}


.check-item textarea {
    padding: 3px;
    font-weight: 500;
    border-radius: 0;
    border: 1px solid black;
    resize: none;
    margin: 1px;
}


.remove-check-button {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.add-button {
    margin-bottom: 18px;
    border-radius: 5px;
    background-color: #153154ca;
    padding: 8px;
    height: auto;
    color: white;
    border: none;
    width: 112px;
    margin-left: 9px;
    font-weight: 600;
    /* box-shadow: 0 3px 3px -3px black; */
    cursor: pointer
}



.add-button:active {
    transform: translateY(1px);
}

.full-issue-body {
    width: 50%;
}

.address-issue-body {
    margin: 10px 20px;
    padding: 0px;
    border-radius: 6px;
    border: 2px solid #db7b7b;
}

.issue-nav-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #6c81ab;
    border-radius: 20px;
    margin-top: 3px;
}

.issue-nav-container h4 {
    margin: 0;
    /* padding: 0 180px; */
    border-radius: 10px;
    font-weight: 500;
    /* background-color: rgba(255, 255, 255, 0.096); */
    color: whitesmoke;
}

.issue-nav-container .nav-btn {
    background-color: rgba(255, 255, 255, 0.096);
    display: flex;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    border-radius: 20px;
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.issue-nav-container .nav-btn:active {
    box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.75);
}

.issue-header-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin-bottom: 0px;
    border: 0px solid black;
    /* border-bottom: 0px solid black; */
    background-color: #db7b7b;
    /* box-shadow: 0 3px 3px -3px black; */
    height: 35px;
}


.issue-header-container h5 {
    padding: 9px;
    font-weight: 500;
    border-radius: 0;
    /* border-right: 1px solid black; */
    margin: 0;
    text-align: start;
}

.issue-details-container {
    display: flex;
    flex-direction: column;
}

.issue-details-title-container {
    display: flex;
    flex-direction: column;
    padding: 6px;
}

.issue-details-body-container {
    display: flex;
    flex-direction: row;
    padding: 3px 9px 9px;
}

.issue-details-body-sub-container {
    display: flex;
    flex-direction: column;
}

.issue-details-body-sub-container label {
    margin-bottom: 3px;
    font-size: 12px;
    font-weight: 500;
}

.issue-details-container textarea {
    resize: none;
    /* box-shadow: 0 3px 3px -3px black; */
    background-color: whitesmoke;
    height: 100%;
    border-radius: 3px;
    border: 2px solid #db7b7b;
}

.addressed-button {
    margin: 18px 0% 18px 0%;
    border-radius: 21px;
    background-color: #153154ca;
    padding: 0;
    height: 30px;
    color: white;
    border: 1px solid white;
    width: 40%;
    font-weight: 600;
    box-shadow: 0 3px 3px -3px black;
}

.addressed-button:active {
    box-shadow: inset 0 3px 3px -3px black;
}

.form-group-vertical label {
    margin-bottom: 3px;
}

.form-group-vertical textarea {
    resize: none;
    /* box-shadow: 0 3px 3px -3px black; */
    border: 0;
    background-color: whitesmoke;

}


.media-container {
    padding: 3px;
    background-color: whitesmoke;
    border: 2px solid #db7b7b;
    border-radius: 3px;
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
}

.image-placeholder {
    height: 90px;
    background-color: transparent;
    margin: 3px;
    display: flex;
    align-items: center;
}

.image-placeholder svg:hover {
    cursor: pointer;
    color: var(--primary-color)
}


.confirm-modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 330px;
    border-radius: 5px;
    overflow: hidden;
    background-color: whitesmoke;
    padding: 18px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
    /* border: 2px solid var(--primary-color); */


}

.confirm-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 72px;
}

.confirm-header h3 {
    font-weight: 700;

}

.confirm-cancel-btn {
    width: 42%;
    margin-left: 3%;
    margin-right: 5%;
    border-radius: 6px;
    height: 30px;
    background-color: whitesmoke;
    margin-bottom: 3%;
    color: #060606;
    border: 1px solid gray;
    font-weight: 600;
    cursor: pointer;
}

.confirm-confirm-btn {
    width: 42%;
    margin-left: 5%;
    margin-right: 3%;
    border-radius: 6px;
    height: 30px;
    background-color: #f53649;
    margin-bottom: 3%;
    color: #dedede;
    border: 1px solid transparent;
    font-weight: 600;
    cursor: pointer;
}


.submit-container button:active {
    box-shadow: 0px 0px 15px -9px rgba(0, 0, 0, 0.75) inset;
}


#spec-sheet-upload-input {
    display: none;
}

.custom-file-upload-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border: 1px solid var(--accent-color);
    border-radius: 3px;
    padding: 5px;
    font-size: 12px;
    height: 20px;
}

.addfile-button {
    background-color: var(--accent-color);
    color: white;
    float: right;
    padding: 5px;
    border-radius: 5px;
}

.optional-label {
    color: gray;
    font-weight: 400;
    margin-left: 3px;
}

.permission-check-container {
    width: 27%;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 5px;
    border: 1px solid var(--accent-color);
}

.permission-check-container input {
    cursor: pointer;
}

.permission-check-container label {
    font-size: 12px;
}

/* address issues sliding container */
.sliding-container {
    display: flex;
    flex-direction: row;
    width: 200%;
}

#slide-left {
    animation-duration: 1s;
    animation-name: slideleft;
    animation-iteration-count: 1;
    animation-direction: alternate;
    animation-fill-mode: forwards;

    -webkit-animation-fill-mode: forwards;
}

#slide-right {
    animation-duration: 1s;
    animation-name: slideright;
    animation-iteration-count: 1;
    animation-direction: alternate;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
}

@keyframes slideleft {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-50%);
    }
}

@keyframes slideright {
    0% {
        transform: translateX(-50%);
    }

    100% {
        transform: translateX(0%);
    }
}