.forgot-password-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100vw;
    height: 100vh;
    background-color: #e7e7e7;

}

.forgot-password-container .left-section,
.forgot-password-container .right-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.forgot-password-container .left-section {

    width: 50%;
}

.forgot-password-container .right-section {

    width: 50%;
}

.forgot-password-container .io-logo {
    position: absolute;
    top: 15px;
    width: 200px;
}

.forgot-password-text h1 {
    color: #000;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    margin: 0;
    width: 586px;
}

.forgot-password-text h3 {
    margin-top: 20px;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    color: black;
    width: 586px;
}

.forgot-password-container .forgot-password-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 50%;
    height: 20%;
}

.forgot-password-container .reset-button {
    border: none;
    margin: 0px;
    border-radius: 15px;
    height: 62px;
    width: 100%;
    padding: 12px 41px;
    transition: all 0.2s ease-in-out;
    font-size: 16px;
    color: white;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.forgot-password-container .reset-button:hover {
    color: white;
    background-color: var(--primary-color);
    scale: 1.03;
    cursor: pointer;
}

.forgot-password-container .forgot-email {
    padding-left: 20px;
    font-size: 16px;
    width: calc(100% - 20px);
    border-radius: 15px;
    height: 62px;
    border: none;
    color: var(--primary-color);
    background-color: white;

}

.forgot-password-container .forgot-email:focus {
    outline-color: var(--primary-color);
    outline: none;
}


@media (max-width: 1225px) {
    .forgot-password-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .forgot-password-container .left-section,
    .forgot-password-container .right-section {
        width: 100%;

    }


    .forgot-password-container .io-logo {
        position: absolute;
        top: 15px;
        width: 200px;
        left: calc(50% - 100px);
    }

    .forgot-password-container .forgot-password-form {
        height: 100%;
    }

    .forgot-password-text h1,
    .forgot-password-text h3 {
        text-align: center;
        width: calc(100% - 40px);
        padding: 0px 20px;
    }

    .forgot-password-container .right-section {
        height: 20%;
        justify-content: flex-start;
    }

    .forgot-password-container .left-section {
        justify-content: flex-end;
    }
}

@media (max-width: 500px) {

    .forgot-password-container .forgot-password-form {
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .forgot-password-container .forgot-email,
    .forgot-password-container .reset-button {
        width: 80%;
    }

    .forgot-password-container .right-section {
        height: 20%;
        justify-content: flex-start;
    }

    .forgot-password-container .left-section {
        justify-content: flex-end;
    }

}

@media (max-height: 512px) {

    .forgot-password-container .forgot-password-form {
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .forgot-password-container .forgot-email,
    .forgot-password-container .reset-button {
        width: 80%;
    }

    .forgot-password-container .right-section {
        height: 40%;
        justify-content: flex-start;
    }

    .forgot-password-container .left-section {
        height: 50%;
        justify-content: flex-end;
    }

}

@media (max-height: 280px) {
    .forgot-password-container .forgot-password-form {
        height: 100%;
        gap: 5px
    }

    .forgot-password-container .io-logo {
        top: 5px;
    }

    .forgot-password-container .left-section h1 {
        font-size: 30px;
    }

}