.file-tree-container {
    width: 96%;
    display: flex;
    height: calc(100% - 60px);
    flex-direction: column;
    /* scrollbar-width: none; */
    /* margin-bottom: 10px; */
}

.file-tree-header {
    width: 100%;
    height: 30px;
    /* border: 1px solid black; */
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--primary-color-eb);
    color: white;
}

.folder-header-label {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.file-tree-header .back-button {
    margin: 0px 3px;
}

.file-tree-header .back-button:hover {
    color: var(--accent-color);
    cursor: pointer;
}


.folder-header-label h4:hover {
    color: var(--accent-color);
    cursor: pointer;
}

.folder-header-label h4 {
    margin: 0px;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.file-tree-body {
    width: 100%;
    display: flex;
    overflow-y: scroll;
    height: auto;
    max-height: calc(100% - 30px - 9px);
    margin-top: 9px;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 90px;
}

.folder-icon-container {
    width: 100px;
    height: 100px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 9px;
}

.folder-icon-container:hover {
    background-color: #00000017;
}

.folder-icon-container p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0px 0px;
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    padding-bottom: 3px;
    user-select: none;
}


.folder-icon {
    cursor: pointer;
}

.file-icon {
    margin: 20px;
    cursor: pointer;
}



.img-preview-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* max-height: 100%; */

}

.img-preview-container img {
    max-width: 100px;
    height: auto;
    max-height: 82px;
    cursor: pointer;
    border: 1px solid #99bafd;
    user-select: none;
}

.pdf-icon {
    cursor: pointer;
}