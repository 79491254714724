.builder-modal-body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 900px;
    height: 600px;
    background-color: whitesmoke;
    border: 1px solid white;
    border-radius: 10px;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 99;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.builder-modal-body .sheet-name-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 18px);
    padding: 12px 9px;
}

.builder-modal-body .sheet-name-input-container input {
    width: 80%;
    border: 1px solid gray;
}

.sheet-name-input-container .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: #7d8999;
    border: 1px solid #284160;
    color: #fff;
    text-align: center;
    padding: 3px 0;
    border-radius: 6px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    top: 54px;
    right: 48px;
}

.sheet-name-input-container:hover .tooltip-text {
    visibility: visible;
    transition: all ease-in-out 0.5s;
}

.add-check-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 9px;
    border-radius: 5px;
    background-color: #153154ca;
    padding: 0;
    height: 26px;
    color: white;
    border: 1px solid white;
    padding: 15px;
    margin-left: 9px;
    font-weight: 600;
    cursor: pointer
}

.sections-scroll-container {
    overflow-y: scroll;
    scrollbar-width: none;
    /* min-height: 320px;
    max-height: 320px; */
    height: 100%;
    border: 1px solid gray;
    padding-bottom: 90px;
    margin: 0px 9px;
    background-color: white;
    border-radius: 5px;
}

.builder-loader-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    padding-bottom: 90px;
}

.kitting-list-scroll-container {
    overflow-y: scroll;
    scrollbar-width: none;
    /* min-height: 320px;
    max-height: 320px; */
    height: 100%;
    border: 1px solid black;
    padding-bottom: 90px;
}

.sections-canvas-container {
    overflow-y: hidden;
    scrollbar-width: none;
    width: 100%;
    height: 100%;
}


.sections-scroll-container .section-item {
    width: 100%;
    background-color: white;
}

@keyframes moveUp {
    0% {
        position: relative;
        transform: translateY(0px);
    }

    100% {
        position: relative;
        transform: translateY(var(--move-distance));
    }
    
}

@keyframes currentMove {
    0% {
        z-index: 1;
        box-shadow : 0px 0px 0px 0px;
    }

    50% {
        z-index: 5;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }

    100% {
        z-index: 1;
        box-shadow: 0px 0px 0px 0px;
    }
}

@keyframes moveDown {
    0% {
        position: relative;
        transform: translateY(0px);
    }

    100% {
        position: relative;
        transform: translateY(var(--move-distance));
    }
    
}


.section-item .header-container {
    width: calc(100% - 18px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 9px;
}

.section-move-container {
    display: flex;
    flex-direction: column;
}

.section-move-container .section-arrow-move {
    fill: black;
    transition: all 0.3s ease-in-out;
}

.section-move-container .section-arrow-move:hover {
    cursor: pointer;
    fill: gray;
}

.section-item .header-container .section-header-text {
    /* width: 60%; */
    flex: 1;
    margin: 0px 9px 0px;
    border: 0px;
    font-size: 21px;
    font-weight: 700;
    background-color: transparent;
}


.section-item .body-container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding: 0; 
    margin: 0;
    scrollbar-width: none;
}

.section-item .body-container .check-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 5px 6px 0px 6px;
    /* background-color: gray; */
    padding: 9px;
    gap: 5px;
    border: 1px solid transparent;
    /* margin-bottom: 3px; */
}

.section-item .body-container .check-item .check-item-start {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 36%;
}

.section-item .body-container .check-item .check-title {
    width: calc(100% - 24px);
    border: 0px;
    font-size: 16px;
    background-color: transparent;
}

.kitting-item {
    width: calc(100% - 32px);
    height: calc(45px);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 6px 6px 0px 6px;
    background-color: white;
    padding: 0px 9px 0px 9px;
    border: 1px solid transparent;
}

.kitting-item .kitting-title {
    height: 100%;
    width: 95%;
    outline: none;
    border: 0px;
    font-size: 15px;
    background-color: transparent;
}

.kitting-item-start {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.section-check-input-container {
    min-width: 155px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 5px;
    background-color: #eaeaea;
    cursor: pointer;
    gap: 5px;
}

.section-check-input-container .section-text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
} 

.section-check-input-container p {
    margin: 0px 6px 0px 0px;
    font-size: 15px;
    user-select: none;
}

.section-check-input-container input::before {
    border-radius: 100%;
    
}


.section-check-button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.add-section-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
    border-radius: 5px;
    background-color: #153154ca;
    padding: 0;
    height: 65px;
    color: white;
    border: none;
    padding: 5px;
    margin-left: 9px;
    font-size: 18px;
    font-weight: 600;
    width: 120px;
    cursor: pointer
}

.section-display-toggle-button {
    width: 100%;
    background-color: silver;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid gray;
}

.section-open {
    display: flex;
    opacity: 1;
    max-height: var(--my-css-var);
}

.section-close {
    display: none;
    opacity: 0;
    max-height: 0px;
}

.draggable-element-container {
    /* -webkit-filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, .7)); */
    filter:  drop-shadow( rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px);
}

.element-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    border-radius: 5px;
    width: calc(100% - 12px);
    height: calc(100% - 12px)
}


.element-title-header {
    margin: 0px;
    font-weight: 500;
    padding: 0px;
    /* white-space: nowrap; */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    user-select: none;
}

.canvas-legend-container {
    background-color:#9badd15a;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    border-top: 1px solid gray;
}

.canvas-legend-container p {
    border: 1px solid gray;
    border-radius: 25px;
    margin:  0px 5px;
    padding: 1px 10px;
    font-size: 15px;
    
}

.canvas-legend-container h4 {
    margin: 3px 9px;
    height: 100%;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}